import { DiseaseCategory } from './entities/case-report.entity';
import { MediaType } from './entities/media-item.entity';

export const QUIZ_WIDGETS = {
  en: {
    [DiseaseCategory.HairLoss]: 'UvdjdEK7',
    [DiseaseCategory.Skincare]: 'GMIa6mgK',
    [DiseaseCategory.ErectileDysfunction]: 'sbCocytL',
    [DiseaseCategory.WeightLoss]: 'p6vu8v11',
    [DiseaseCategory.PrematureEjaculation]: 'S5I68p73',
  },
  sv: {
    [DiseaseCategory.HairLoss]: 'HffD5gSp',
    [DiseaseCategory.Skincare]: 'aPsKBgtd',
    [DiseaseCategory.ErectileDysfunction]: 'K7JaYgd2',
    [DiseaseCategory.WeightLoss]: 'Owy6Qz6p',
    [DiseaseCategory.PrematureEjaculation]: 'g5qlrVwc',
  },
};

export const PRESCRIPTION_ONLY_PRODUCT_SKU = 'PRESCRIPTION_ONLY';

export const SERVICE_FEE_PRODUCT_SKU = 'SERVICE_FEE';

// Define a mapping of MIME types to MediaType values
export const MIME_TYPE_TO_MEDIA_TYPE: Record<string, MediaType> = {
  'image/jpg': MediaType.Image,
  'image/jpeg': MediaType.Image,
  'image/png': MediaType.Image,
  'image/bmp': MediaType.Image,
  'video/*': MediaType.Video,
  'application/pdf': MediaType.Document,
  'application/msword': MediaType.Document,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': MediaType.Document,
  'text/plain': MediaType.Document,
  'application/vnd.ms-excel': MediaType.Document,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': MediaType.Document,
};

export const DEFAULT_FONT_SCALE = 100;
export const MAX_FONT_SCALE = 120;
export const MIN_FONT_SCALE = 80;

export const REFETCH_MESSAGES_INTERVAL_SECONDS = 15;
