import React, { useState } from 'react';
import { appWithTranslation, useTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
// All external css files have to be located before _app.css
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-phone-number-input/style.css';
import '@etchteam/next-pagination/dist/index.css';
import 'react-pivottable/pivottable.css';
import '../styles/_app.scss';
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-getcanonicallocales/polyfill';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/sv';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/sv';
import Head from 'next/head';
import { NextPage } from 'next';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import ReduxToastr from 'react-redux-toastr';
import dynamic from 'next/dynamic';
import nextI18NextConfig from '../next-i18next.config';
import createStoreConfig from '../model/create-store-config';
import AppContainer from './app-container';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

// TODO: Replace with a standard Bootstrap tooltip
// @ts-ignore Tooltip is ExoticComponent which does not extend
// ComponentType, but in reality is compatible.
const Tooltip: any = dynamic(() => import('react-tooltip').then(module => module.Tooltip), {
  ssr: false,
});

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const [storeConfig] = useState<ReturnType<typeof createStoreConfig>>(createStoreConfig);

  const { t } = useTranslation();

  const getLayout = Component.getLayout || (page => page);

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={storeConfig.store}>
        <Head>
          <meta name="robots" content="noindex, nofollow" />
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/img/favicon/apple-touch-icon.png?v=1"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/img/favicon/favicon-32x32.png?v=1"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/img/favicon/favicon-16x16.png?v=1"
          />
          <link rel="manifest" href="/site.webmanifest?v=1" />
          <link rel="icon" href="/img/favicon/favicon.ico?v=1" />
          <meta name="apple-mobile-web-app-title" content="Ayd" />
          <meta name="application-name" content="Ayd" />
          <meta name="msapplication-TileColor" content="#de2938" />
          <meta name="msapplication-config" content="/browserconfig.xml?v=1" />
          <meta name="theme-color" content="#ffffff" />

          <link
            href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&amp;display=swap"
            rel="stylesheet"
          />
          <link rel="preconnect" href="//fonts.gstatic.com/" crossOrigin="" />

          <title>{t('APP.META_DEFAULT.TITLE')}</title>
        </Head>
        <AppContainer>
          {getLayout(<Component {...pageProps} />)}
          <ReduxToastr
            timeOut={5200}
            newestOnTop
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            closeOnToastrClick
          />
          <Tooltip anchorSelect=".tooltip-anchor" />
        </AppContainer>
      </Provider>
    </QueryClientProvider>
  );
};

export default appWithTranslation(App, nextI18NextConfig);
