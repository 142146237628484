import { BaseEntity } from './shared/base-entity';

export enum MediaType {
  Image = 'image',
  Video = 'video',
  Document = 'document',
}

export interface MediaItemMetadata {
  uuid: string;
  fileName: string;
  encoding: string;
  mimeType: string;
  size: number;
}

export enum PhotoAngle {
  Left = 'left',
  Right = 'right',
  Front = 'front',
  Back = 'back',
}

export interface MediaItem extends BaseEntity {
  userId: string;
  caseReportId: string;
  mediaType: MediaType;
  metadata: MediaItemMetadata;
  photoAngle: PhotoAngle | null;
  isMessageAttachment: boolean;
}
