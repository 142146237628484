/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from './app.state';
import { DEFAULT_FONT_SCALE } from '../../constants';
import { CaseReportsListQuery } from '../../types/case-reports-list-query';

const appInitialState: AppState = {
  isBoxed: false,
  sidebarIsOpen: true,
  sidebarIsSticky: false,
  loaderOverlayVisible: false,
  fontScale: DEFAULT_FONT_SCALE,
  defaultListFilters: {},
  lastCaseReportsListQuery: null,
};

const app = createSlice({
  name: 'app',
  initialState: appInitialState,
  reducers: {
    toggleSidebarIsOpen: state => {
      state.sidebarIsOpen = !state.sidebarIsOpen;
    },
    toggleBoxed: state => {
      state.isBoxed = !state.isBoxed;
    },
    setLoaderOverlayVisible: (state, action: PayloadAction<boolean>) => {
      state.loaderOverlayVisible = action.payload;
    },
    setFontScale: (state, action: PayloadAction<number>) => {
      state.fontScale = action.payload;
    },
    setDefaultListFilters: (state, action: PayloadAction<Record<string, any>>) => {
      state.defaultListFilters = action.payload;
    },
    setLastCaseReportsListQuery: (state, action: PayloadAction<CaseReportsListQuery>) => {
      state.lastCaseReportsListQuery = action.payload;
    },
  },
});

export const { actions } = app;

export default app.reducer;
