import Environment from './types/environment';

export interface ClientConfig {
  siteUrl: string;
  apiUrl: string;
  environment: Environment;
  aydWebUrl: string;
}

export interface ServerConfig extends ClientConfig {}

export const clientConfig: ClientConfig = {
  siteUrl: process.env.NEXT_PUBLIC_SITE_URL || '',
  apiUrl: process.env.NEXT_PUBLIC_API_URL || '',
  environment: (process.env.NEXT_PUBLIC_ENVIRONMENT || 'local') as Environment,
  aydWebUrl: process.env.NEXT_PUBLIC_AYD_WEB_URL || '',
};

export const serverConfig: ServerConfig = {
  ...clientConfig,
};
