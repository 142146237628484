export interface LocalizedPathConfig {
  path: string;
  defaultLocale?: string;
  locale: string;
}

const getLocalizedPath = ({ path, defaultLocale, locale }: LocalizedPathConfig): string => {
  if (locale === defaultLocale) {
    return path;
  }
  const url = new URL(path, 'https://example.com');
  const noTrailingSlashPath = url.pathname.endsWith('/') ? url.pathname.slice(0, -1) : url.pathname;
  url.pathname = `/${locale}${noTrailingSlashPath}`;
  return url.href.replace('https://example.com', '');
};

export default getLocalizedPath;
