import { combineReducers } from '@reduxjs/toolkit';
import { reducer as toastr } from 'react-redux-toastr';
import appReducer from '../app/app.slice';

const rootReducer = combineReducers({
  toastr,
  app: appReducer,
});

export default rootReducer;
